@media (min-width: 779.95px) {
    .desktopHidden {
        display: none !important;
    }
}
@media (max-width: 780px) {
    .mobileHidden {
        display: none !important;
    }
}
